import axios from 'axios'

const API_BASE_URL = window.env.REACT_APP_DEMO_BACKEND_URL
let cachedConfig = null

export const retrieveConfig = async () => {
    if (cachedConfig) {
        return cachedConfig
    }

    const hostName = window.location.hostname

    try {
        const response = await axios.post(API_BASE_URL + '/config', {
            domain: hostName,
        })

        cachedConfig = response.data
        return cachedConfig
    } catch (error) {
        console.error(`Error retrieving config: ${error}`)
        throw error
    }
}

export const getConfig = async () => {
    const config = await retrieveConfig()

    return config
}

export function getSentryEnvKey() {
    switch (window.env.REACT_APP_DEMO_BACKEND_URL) {
        case 'https://hosted-page-backend.ecomm-stg.dmgsecure.io':
            return 'staging'
        case 'https://hosted-page-backend.ecomm-dev2.dmgsecure.io':
            return 'development'
        case 'https://hosted-page-backend.ecomm-prd.dmgsecure.io':
            return 'production'
        default:
            return 'local'
    }
}
