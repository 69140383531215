import { useCallback } from 'react'

export const useInputChange = (formFields, setValue) => {
    return useCallback(
        e => {
            let value = e.target.value
            const fieldConfig = formFields.find(field => field.name === e.target.name)

            if (fieldConfig?.validation?.max && value > fieldConfig.validation.max) {
                value = fieldConfig.validation.max
                setValue('amount', value)
            }
        },
        [formFields, setValue],
    )
}
