import React from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { marked } from 'marked'
import DOMPurify from 'dompurify'
import { Helmet } from 'react-helmet'

import { retrieveConfig } from '../../config/config'
import PaymentModel from '../payment-model'
import HostedForm from '../hosted-form'
import { LinkParser } from '../../utils/link-parser'
import InvalidLink from '../InvalidLink'

const API_BASE_URL = window.env.REACT_APP_DEMO_BACKEND_URL

function withParams(Component) {
    // eslint-disable-next-line react/display-name
    return props => <Component {...props} params={useParams()} />
}
class HostedPage extends React.Component {
    data = {}

    constructor(props) {
        super(props)
        this.state = {
            params: {},
            isSecureLink: false,
            showPaymentScreen: false,
            poweredByimages: [],
            showInvalidLink: false,
            showNoLinkError: false,
            invalidLinkMessage: {
                title: '',
                body: '',
            },
            formErrors: {
                code: false,
            },
            backgroundColor: 'white',
            background: '',
            poweredByText: '',
        }

        this.myRef = React.createRef()
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        const forms = document.querySelectorAll('.needs-validation')

        // Loop over them and prevent submission
        Array.from(forms).forEach(form => {
            form.addEventListener(
                'submit',
                event => {
                    if (!form.checkValidity()) {
                        event.preventDefault()
                        event.stopPropagation()
                    }

                    form.classList.add('was-validated')
                },
                false,
            )
        })
    }

    async componentDidMount() {
        const selectedConfig = await retrieveConfig()

        if (!selectedConfig.title) {
            this.setState({
                showInvalidLink: true,
                invalidLinkMessage: {
                    title: 'Merchant not found',
                    body: selectedConfig?.error?.message ?? 'merchant not found',
                },
            })
        } else {
            const title = selectedConfig.title
            const logo = selectedConfig.logo.url

            const supportEmail = selectedConfig?.supportEmail ?? ''
            const mailto = 'mailto:'.supportEmail

            const { data } = this.props.params

            this.setState({
                selectedConfig,
                title,
                logo,
                supportEmail,
                mailto,
                backgroundColor: selectedConfig.backgroundColor,
                background: selectedConfig.background,
                poweredByImages: this.getPoweredByIcons(selectedConfig),
            })

            if (!data) {
                this.setState({ isSecureLink: false })
                if (!selectedConfig.allowNoSecureLinks || !selectedConfig.enableStaticLinks) {
                    this.setState({
                        showInvalidLink: true,
                        invalidLinkMessage: {
                            title: 'Direct Access prohibited',
                            body: 'You need to navigate using a link provided by the merchant. Please contact your merchant for a link or QR code.',
                        },
                    })
                }
            }

            if (data) {
                const domain = selectedConfig.domain || window.location.hostname
                this.setState({ isSecureLink: true })
                axios
                    .post(API_BASE_URL + '/decode-link-params', {
                        encryptedData: data,
                        domain,
                    })
                    .then(data => {
                        if (!data.data.result) {
                            this.setState({
                                showInvalidLink: true,
                                invalidLinkMessage: {
                                    title: 'Malformed URL',
                                    body: 'The link you entered does not look like a valid link. If someone gave you this link, you may need to ask them to check that it is correct.',
                                },
                            })
                            return
                        }

                        const qs = data.data.decryptedData

                        if (!qs) {
                            return
                        }

                        const formParams = LinkParser(qs)

                        this.setState({ params: formParams })
                    })
                    .catch(error => {
                        console.error('Unable to decode link', error)

                        this.setState({
                            showInvalidLink: true,
                            invalidLinkMessage: {
                                title: 'Invalid Link',
                                body: 'There was an issue and we could not process the link you entered. Please try again or contact your merchant for a new link.',
                            },
                        })
                    })
            }
        }
    }

    showModel() {
        this.setState({
            showPaymentScreen: true,
        })
    }

    getPoweredByIcons(selectedConfig) {
        const cdnHost = window.env.REACT_APP_CDN_HOST
        const urlRoot = cdnHost + '/app'

        if (selectedConfig?.poweredByImages) {
            return selectedConfig.poweredByImages.map(element => {
                return {
                    key: element.key,
                    url: element.url.replace('%CDN_HOST%', urlRoot),
                }
            })
        }

        return []
    }
    render() {
        const poweredByText = this.state.selectedConfig?.poweredByText ?? 'Powered by'

        if (this.state.showInvalidLink) {
            return (
                <div>
                    <div className="hpp-background"></div>
                    <div className="App md:flex md:max-w-screen-lg items-stretch justify-center md:m-auto md:mt-5 font-sans bg-slate-100">
                        <div className="content-body flex-1 flex flex-col items-stretch bg-white space-y-7 md:space-y-20 md:px-10 pt-3 justify-between">
                            <header className="md:flex  justify-between sm:pt-3 md:pt-0 pl-3 pr-3 border-b-2 border-gray-900">
                                {this.state.selectedConfig && (
                                    <>
                                        <div className="header-logo flex justify-end">
                                            <img
                                                src={this.state.selectedConfig.logo.url}
                                                alt={this.state.selectedConfig.logo.alt}
                                                style={{
                                                    maxWidth: this.state.selectedConfig.logoMaxWidth,
                                                    marginTop: this.state.selectedConfig.logo.marginTop ?? '20px',
                                                    marginBottom: '10px',
                                                }}
                                            />
                                        </div>
                                        <div className="text-right text-sm self-center">
                                            <div>
                                                <a className=" text-gray-500 top-comm-link" href="tel:18002095438">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"></path>
                                                    </svg>{' '}
                                                    {this.state.supportPhone}
                                                </a>{' '}
                                            </div>
                                            <div>
                                                <a className=" text-gray-500 top-comm-link" href={this.state.mailto}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"></path>
                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                    </svg>{' '}
                                                    {this.state.supportEmail}
                                                </a>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {!this.state.selectedConfig && (
                                    <>
                                        <div className="header-logo flex justify-end">
                                            <img
                                                src="https://cdn.ecomm-prd.dmgsecure.io/demo/datamesh-logo.png"
                                                alt="Data Mesh Group"
                                                style={{ maxWidth: '200px', marginTop: '20px' }}
                                            />
                                        </div>
                                        <div className="text-right text-sm self-center">
                                            <div>
                                                <a className=" text-gray-500 top-comm-link" href="tel:18002095438">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"></path>
                                                    </svg>
                                                    {this.state.supportPhone}
                                                </a>
                                            </div>
                                            <div>
                                                <a className=" text-gray-500 top-comm-link">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"></path>
                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                    </svg>
                                                    {this.state.supportEmail}
                                                </a>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </header>
                            <div role="main" className="grow text-slate-700 "></div>
                            <InvalidLink
                                title={this.state.invalidLinkMessage.title}
                                body={this.state.invalidLinkMessage.body}
                            ></InvalidLink>
                        </div>
                    </div>
                </div>
            )
        }

        if (!this.state.selectedConfig) {
            return <div></div>
        }

        const content = this.state.selectedConfig.content
            ? DOMPurify.sanitize(marked.parse(this.state.selectedConfig.content))
            : ''
        const textColor = this.state.selectedConfig.textColor
        const poweredByImageKeys = this.state.selectedConfig.poweredBy
        const poweredByImages = this.state.poweredByImages

        return (
            <div>
                <div className="hpp-background"></div>
                <div className="App md:flex md:max-w-screen-lg items-stretch justify-center md:m-auto md:mt-5 font-sans bg-slate-100">
                    <div className="content-body flex-1 flex flex-col items-stretch bg-white space-y-7 md:px-10 pt-3 justify-between">
                        <Helmet>
                            <title>{this.state.selectedConfig?.title}</title>
                            {this.state.selectedConfig?.favIcons?.map((icon, index) => (
                                <link key={index} rel="icon" href={icon.url} sizes={icon.size} />
                            ))}
                        </Helmet>
                        <header className="md:flex  justify-between sm:pt-3 md:pt-0 pl-3 pr-3 border-b-2 border-gray-900">
                            <div className="header-logo flex justify-end">
                                <img
                                    src={this.state.logo}
                                    alt={this.state.title}
                                    style={{
                                        maxWidth: this.state.selectedConfig.logo.maxWidth ?? '220px',
                                        marginTop: this.state.selectedConfig.logo.marginTop ?? '20px',
                                        marginBottom: '10px',
                                    }}
                                />
                            </div>
                            <div className="text-right text-sm self-center">
                                <div>
                                    <a className=" text-gray-500 top-comm-link" href="tel:18002095438">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"></path>
                                        </svg>{' '}
                                        {this.state.selectedConfig.supportPhone}
                                    </a>{' '}
                                </div>
                                <div>
                                    <a className=" text-gray-500 top-comm-link" href={this.state.mailto}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"></path>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                        </svg>{' '}
                                        {this.state.supportEmail}
                                    </a>
                                </div>
                            </div>
                        </header>
                        <div role="main" className="grow hpp-content-area">
                            {this.state?.selectedConfig?.maintenance?.isActive && (
                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                                    <strong className="font-bold">Maintenance Alert!</strong>
                                    <span className="block sm:inline">{this.state.selectedConfig.maintenance.message}</span>
                                </div>
                            )}
                            <div
                                className="space-y-4 p-3"
                                style={{ color: textColor }}
                                dangerouslySetInnerHTML={{ __html: content }}
                            ></div>
                        </div>
                        <footer className="mt-10 flex justify-between md:ml-5 md:mr-5 md:mb-5 text-sm p-3">
                            {poweredByImageKeys && poweredByImages && (
                                <>
                                    <div className="pr-1 pt-1 powerered-by-text" style={{ color: textColor }}>
                                        {poweredByText}
                                    </div>
                                    {poweredByImages.map(
                                        image =>
                                            poweredByImageKeys.includes(image.key) && (
                                                <div key={image.key}>
                                                    <img src={image.url} />
                                                </div>
                                            ),
                                    )}
                                </>
                            )}
                        </footer>
                    </div>
                    {!this.state?.selectedConfig?.maintenance?.isActive && (
                        <HostedForm
                            formFields={this.state.selectedConfig.formFields}
                            formType={this.state.isSecureLink ? 'secure_link' : 'general_form'}
                            onHandleSubmit={data => this.myRef.current.toggle(data)}
                            params={this.state.params}
                        />
                    )}
                </div>

                <PaymentModel showModel={this.state.showPaymentScreen} ref={this.myRef} />
            </div>
        )
    }
}

export default withParams(HostedPage)
