const localFormattedDateTime = (currency = 'en-IN') => {
    const now = new Date()

    const date = now.toLocaleDateString(currency)
    const time = now.toLocaleTimeString(currency)

    return `${date} ${time}`
}

export { localFormattedDateTime }
