import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import HostedPage from './components/hosted-page'
import { getSentryEnvKey } from './config/config'

import './assets/main.css'
import './index.scss'
import './App.css'

const baseUri = window.env.REACT_APP_DEMO_BASE_URI

// TODO get from package.json somehow
const version = process.env.REACT_APP_VERSION
console.info('sdk-hpp-fe v' + version)

const router = createBrowserRouter(
    [
        {
            path: '/',
            element: <HostedPage />,
        },
        {
            path: '/:data',
            element: <HostedPage />,
        },
    ],
    {
        basename: baseUri,
    },
)

Sentry.init({
    environment: getSentryEnvKey(),
    dsn: 'https://ec10b630d83943c6d8bfca3eb807faa0@o4505706145775616.ingest.sentry.io/4506538308468736',
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: ['localhost', /^https:\/\/[a-z-]+\.hosted-page\.ecomm-[A-Za-z0-9]+\.dmgsecure\.io/],
        }),
        new Sentry.Replay({
            maskAllText: true,
            blockAllMedia: false,
        }),
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.createRoot(document.getElementById('root')).render(<RouterProvider router={router} />)
