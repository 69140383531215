import React from 'react'
import { DetailRow } from '../index'

export default function Card(props) {
    return (
        <>
            <DetailRow label="Cardholder Number" value={props.data.cardholderNumber} />
            <DetailRow label="Expiry Date (mm/yy)" value={props.data.expiryDate} />
        </>
    )
}
