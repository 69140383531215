export const themeBuilder = hppConfig => {
    const selectedConfig = hppConfig

    return {
        button_border_radius: '10',
        color_primary: selectedConfig.SDK_THEME_COLOR_PRIMARY ?? '#F03D45',
        frame_background_color: '#FFFFFF',
        frame_border_color: '#ced4da',
        frame_border_radius: '16',
        frame_border_width: '0',
        header_text_color: '#32325C',
        input_border_width: '1',
        input_border_radius: '5',
        body_font_size: '20',
        body_font_weight: '900',
        body_line_height: '1',
        frame_box_shadow: '0',
        frame_box_style: 'solid',
        input_field_background_color: '#FFFFFF',
        input_label_asterisk_color: '#FF0000',
        input_label_empty_color: '#999999',
        input_label_focused_color: '#981B47',
        input_empty_frame_color: '#ced4da',
        input_focused_frame_color: '#981B47',
        input_field_padding: '0',
        input_text_font_size: '16',
        input_text_font_weight: '400',
        input_text_line_height: '1',
        input_text_font_url: 'https://fonts.googleapis.com/css?family=Lato',
        body_font: {
            family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            url: 'https://fonts.googleapis.com/css?family=Lato:300,400,700,900',
        },
        header_font: {
            family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            url: 'https://fonts.googleapis.com/css?family=Lato:300,400,700,900',
        },
    }
}
