import React from 'react'

export default function EmailSentMessage(props) {
    if (props.isError) {
        return (
            <div className="message emailReceiptError">
                There was an issue and the payment receipt could not be sent at the moment.
            </div>
        )
    }

    return <div className="message emailReceiptError">Receipt has been sent to provided email address</div>
}
