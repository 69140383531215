import React from 'react'
import ModalHeader from './modal-header'

import PaymentSuccessExample from '../PaymentSuccess'
import PaymentFailure from '../PaymentFailure'
import PaymentModelLoader from '../payment-model-loader'
import GeneralError from '../general-error'

const PaymentForm = ({ isLoaded }) => (
    <form id="demoPaymentForm" className="relative  flex-auto" noValidate>
        {!isLoaded && <PaymentModelLoader />}
        <div id="paymentDmgSdkBox" className="dmg-pay-widget  bg-white"></div>
    </form>
)
const ModalContent = ({
    showReceiptScreen,
    chargeId,
    receiptDetails,
    showFailureScreen,
    message,
    showGeneralError,
    isLoaded,
}) => (
    <div className="modal-header w-100">
        <ModalHeader />
        <PaymentSuccessExample visible={showReceiptScreen} chargeId={chargeId} receiptDetails={receiptDetails} />
        <PaymentFailure visible={showFailureScreen} chargeId={chargeId} message={message} />
        <GeneralError visible={showGeneralError} message={message} />
        <PaymentForm isLoaded={isLoaded} />
    </div>
)

export default ModalContent
