import React from 'react'
export default function InvalidLink(props) {
    const title = props.title ?? 'Error'
    const body = props.body ?? 'An unexpected error has occurred'

    return (
        <div className="block rounded-lg bg-white p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] w-100 dark:bg-neutral-700">
            <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">{title}</h5>
            <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">{body}</p>
        </div>
    )
}
