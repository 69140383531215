import * as yup from 'yup'

export const formSchemaBuilder = formFields => {
    const digitsOnly = value => /^[0-9]*$/.test(value)
    const digitsWithDecimals = value => /^[0-9]*\.?[0-9]*$/.test(value)
    const alphanumericOnly = value => /^[a-zA-Z0-9]*$/.test(value)

    const schema = yup.object().shape(
        formFields.reduce((acc, field) => {
            let validator = yup.string()

            const validation = field.validation

            if (validation.type === 'number') {
                validator = yup.number().nullable().typeError(`Must be a number`)
            }

            if (validation) {
                if (validation.pattern) {
                    // disabling this check, purposely dynamic regex validation to be done at Mentor
                    // eslint-disable-next-line security/detect-non-literal-regexp
                    validator = validator.matches(new RegExp(validation.pattern), 'Invalid format')
                }
                if (validation.minLength) {
                    validator = validator.min(validation.minLength, `Should be at least ${validation.minLength} characters long`)
                }

                if (validation.maxLength) {
                    validator = validator.max(validation.maxLength, `Should be no longer than ${validation.maxLength} characters`)
                }
                if (validation.digitsWithDecimals) {
                    validator = validator.test(
                        'digitsWithDecimals',
                        field.errorMessages.digitsWithDecimals || 'Invalid format',
                        digitsWithDecimals,
                    )
                }
                if (validation.alphanumericOnly) {
                    const errorMessage =
                        field.errorMessages && field.errorMessages.alphanumericOnly
                            ? field.errorMessages.alphanumericOnly
                            : 'Invalid format'
                    validator = validator.test('alphanumericOnly', errorMessage, alphanumericOnly)
                }
                if (validation.digitsOnly) {
                    validator = validator.test('digitsOnly', field.errorMessages?.digitsOnly || 'Invalid format', digitsOnly)
                }
            }

            if (validation.type === 'number') {
                if (validation.min) {
                    validator = validator.min(validation.min, field.errorMessages?.min || `Should be at least ${validation.min}`)
                }

                if (validation.max) {
                    validator = validator.max(
                        validation.max,
                        field.errorMessages?.max || `Should be no more than ${validation.max}`,
                    )
                }
            }
            if (field.required) {
                // Set optional to false for required fields
                validator = validator.required(`Is required`)
            } else {
                // Set optional to true for non-required fields
                validator = validator.nullable().optional()
            }

            acc[field.name] = validator
            return acc
        }, {}),
    )
    return schema
}
