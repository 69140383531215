import React from 'react'

export default class ModalHeader extends React.Component {
    toggle = () => {
        // Right now it's much safer to implement as a reload of window/tab then a destroy of window because
        // destroy doesn't guarantee an actual full destroy with side effects occurring with double up SDK
        window.location.reload(false)
    }

    render() {
        return (
            <div className="modal-header w-100">
                <div className="modal-header self-end">
                    <button
                        className="modal-close-btn absolute top-0 right-0 z-10 background-transparent font-bold uppercase text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 hover:text-red"
                        type="button"
                        onClick={() => this.toggle()}
                    >
                        <i className="fa-solid fa-xmark">X</i>
                    </button>
                </div>
            </div>
        )
    }
}
