import React from 'react'

export default function PaymentFailure(props) {
    if (!props.visible) {
        return null
    }

    return (
        <div className="error-box payment_failed">
            <div className="successDetails">
                <div className="flex">
                    <div className="flex-1 w-32 text-left">{props.message}</div>
                </div>
            </div>
        </div>
    )
}
