// eslint-disable-next-line no-unused-vars
export function sdkCallback(createdCallback, initialCallback, errorCallback, paymentCompletedCallback, paymentFailedCallback) {
    window.DataMesh.on('order.created', createdCallback)

    window.DataMesh.on('initialized', initialCallback)

    window.DataMesh.on('error', errorCallback)

    window.DataMesh.on('payment.completed', paymentCompletedCallback)

    window.DataMesh.on('payment.failed', paymentFailedCallback)
}
