import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { useMountParams } from './useMountParams'
import { formSchemaBuilder } from '../../utils/form-schema-builder'
import { useInputChange } from './useInputChange'
import { useSubmit } from '../../utils/use-submit'
import { FormFields } from './FormFields'
import { useLocation } from 'react-router-dom'
import { retrieveConfig } from '../../config/config'

export default function HostedForm(props) {
    const formFields = props.formFields
    const schema = formSchemaBuilder(formFields)
    const location = useLocation()
    const [paymentOptionsText, setPaymentOptionsText] = useState(0)
    const [paymentOptionsTextColor, setPaymentOptionsTextColor] = useState(0)
    const [paymentOptionsBackgroundColor, setPaymentOptionsBackgroundColor] = useState(0)
    const [backgroundColor, setBackgroundColor] = useState(0)
    const [paymentOptionsTitle, setPaymentOptionsTitle] = useState(0)
    const [background, setBackground] = useState(0)

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    useEffect(() => {
        async function fetchData() {
            const config = await retrieveConfig()

            setPaymentOptionsText(config.form.paymentOptionsText)
            setPaymentOptionsTextColor(config.form.paymentOptionsTextColor)
            setPaymentOptionsBackgroundColor(config.form.paymentOptionsBackgroundColor)
            setBackgroundColor(config.form.backgroundColor)
            setBackground(config.form.background)
            setPaymentOptionsTitle(config.form.title)
        }

        fetchData()

        const params = new URLSearchParams(location.search)
        formFields.forEach(field => {
            const paramValue = params.get(field.name)
            if (paramValue) {
                setValue(field.name, paramValue)
            }
        })
    }, [
        formFields,
        location.search,
        setValue,
        paymentOptionsText,
        setPaymentOptionsText,
        paymentOptionsTextColor,
        setPaymentOptionsTextColor,
        paymentOptionsBackgroundColor,
        setPaymentOptionsBackgroundColor,
        backgroundColor,
        setBackgroundColor,
        background,
        setBackground,
        paymentOptionsTitle,
        setPaymentOptionsTitle,
    ])

    const lockedParams = useMountParams(props.params, setValue)
    const inputChange = useInputChange(formFields, setValue)
    const onSubmit = useSubmit(props.onHandleSubmit)

    document.addEventListener('onPaymentSuccess', () => {
        reset()
    })

    const payOptionsButtonText = paymentOptionsText ?? 'Payment Options'
    const buttonTextColor = paymentOptionsTextColor ?? 'white'
    const paymentOptionsBgColor = paymentOptionsBackgroundColor ?? '#ef4444'
    const formType = props.formType

    return (
        <form
            data-testid="payment-form"
            className="flex flex-2 flex-col bg-gray-100 pt-3 pb-2 pl-4 pr-4 space-y-2"
            style={{ backgroundColor: backgroundColor, backgroundImage: `url(${background})`, position: 'relative' }}
            onSubmit={handleSubmit(onSubmit)}
        >
            <h4 className="font-semibold text-lg text-blue-900">{paymentOptionsTitle ?? 'Enter Payment Details'}</h4>

            <FormFields
                formFields={formFields}
                errors={errors}
                lockedParams={lockedParams}
                inputChange={inputChange}
                register={register}
                formType={formType}
            />

            <div className="relative mt-3" style={{ position: 'absolute', bottom: '15px', left: '15px', right: '15px' }}>
                <input
                    data-testid="payment-submit-btn"
                    type="submit"
                    className="w-full py-2 font-medium text-white bg-red-600 hover:bg-red-500 rounded-lg border-red-500 hover:shadow inline-flex space-x-2 items-center justify-center"
                    style={{ color: buttonTextColor, backgroundColor: paymentOptionsBgColor }}
                    value={payOptionsButtonText}
                ></input>
            </div>
        </form>
    )
}
