// Define a mapping object for IDs to labels
const idToLabelMap = {
    card_instrument: 'Card',
    netbanking_instrument: 'Netbanking',
    upi_instrument: 'UPI',
    wallet_india_instrument: 'Wallet',
    virtual_account_dynamic_instrument: 'Virtual Account',
    // Add more mappings as needed
}

export function paymentTypeIdToLabel(idString) {
    // Validate the input against the mapping object
    if (idString in idToLabelMap) {
        return idToLabelMap[String(idString)]
    } else {
        // Handle invalid or unknown IDs
        return 'Unknown'
    }
}
