import { useState, useEffect } from 'react'
import { trimStringValues } from '../../utils/trim-string-values'

export const useMountParams = (params, setValue) => {
    const [lockedParams, setLockedParams] = useState(0)

    useEffect(() => {
        const mountParams = () => {
            const trimmedParams = trimStringValues(params)

            let lockParams = false

            Object.keys(trimmedParams).forEach(key => {
                if (trimmedParams[String(key)]) {
                    // check for snake keys and convert to Camel case
                    const finalKey = key.replace(/([A-Z])/g, '_$1').toLowerCase()

                    setValue(finalKey, decodeURIComponent(trimmedParams[String(key)]))

                    lockParams = true
                }
            })

            setLockedParams(lockParams)
        }

        mountParams()
    }, [setValue, params])

    return lockedParams
}
