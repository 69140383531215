/**
 * @jest-environment jsdom
 */
import React from 'react'

import axios from 'axios'

import { useParams } from 'react-router-dom'

import { retrieveConfig } from '../../config/config'

import { PaymentModel } from './PaymentModel'

retrieveConfig()
    .then(() => {
        axios.interceptors.request.use(function (config) {
            const domain = window.location.hostname
            config.headers['domain'] = domain
            return config
        })
    })
    .catch(error => {
        console.error(error)
    })

function withParams(Component) {
    // eslint-disable-next-line react/display-name
    return React.forwardRef((props, ref) => <Component ref={ref} {...props} params={useParams()} />)
}

export const tokenisationPublicKey = process.env.REACT_APP_ENCRYPTION_PUBLIC_KEY

export default withParams(PaymentModel)
