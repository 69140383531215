import React from 'react'
import FormField from './FormField'

export const FormFields = ({ formFields, errors, inputChange, register, formType }) => {
    return formFields.map(customField => (
        <FormField
            key={customField.name}
            customField={customField}
            errors={errors}
            inputChange={inputChange}
            register={register}
            formType={formType}
        />
    ))
}
