import React from 'react'

const stylingObject = {
    container: {
        minHeight: '493px',
        maxWidth: '500px',
        overflowAnchor: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },

    errorText: {
        color: '#939393',
        textAlign: 'center',
        fontFamily: 'arial, sans-serif',
        fontSize: '24px',
    },
}

export default class GeneralError extends React.Component {
    render() {
        if (!this.props.visible) {
            return null
        }
        return (
            <div style={stylingObject.container}>
                <div className="error-text" style={stylingObject.errorText}>
                    {this.props.message}
                </div>
            </div>
        )
    }
}
