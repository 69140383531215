import React from 'react'
import { DetailRow } from '../index'

export default function Bank(props) {
    return (
        <>
            <DetailRow label="Bank Names" value={props.data.bankName} />
        </>
    )
}
