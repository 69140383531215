export const LinkParser = qs => {
    try {
        // Try to parse as JSON
        let parsedJson = JSON.parse(qs)
        if (typeof parsedJson === 'object' && parsedJson !== null) {
            return parsedJson.data
        }
    } catch (e) {
        // If it's not JSON, fall back to the existing logic
        return qs.split('&').reduce((a, c) => {
            let [key, value] = c.split('=')

            var camelCasedKey = key.replace(/_([a-z])/g, function (g) {
                return g[1].toUpperCase()
            })

            a[String(camelCasedKey)] = value

            return a
        }, {})
    }
}
